import React, { useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { ThemeContext } from "../../Context/ThemeContext";
import "../../styles/app.css";
import { generateSmartDeliveryURLS } from "../../utils/cloudinary";
import { generateTableOfContent } from "../../utils/table-of-content";
import BlogSlider from "../BlogSlider/blogSlider";
import SEO from "../seo";

const SinglePost = ({ post }) => {
  const theme = useContext(ThemeContext);
  const darkMode = theme?.state?.darkMode;
  const { allH2 } = generateTableOfContent(post.html);
  const onClickLink = (id) => {
    if (window && window.document) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const url = typeof window !== "undefined" ? window.location.href : "";

  const title = post?.meta_title || post.title
  const metaDescription = post?.meta_description

  // useEffect(() => {
  // if (typeof window !== 'undefined') {
  //   function init() {
  //     var vidDefer = document.getElementsByTagName('iframe');
  //     for (var i = 0; i < vidDefer.length; i++) {
  //       if (vidDefer[i].getAttribute('data-src')) {
  //         vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
  //       }
  //     }
  //   }
  //   window.onload = init;
  // }
  // }, []);

  return (
    <div>
      <SEO
        title={title}
        metaDescription={metaDescription}
        canonical={url}
      />
      <div
        className={` ${darkMode ? "blog_container_dark single_post" : "blog_container"
          }`}
      >
        <div className="global">
          <article className="content mt-5 pt-5">
            {post.feature_image ? (
              <img
                src={generateSmartDeliveryURLS(post.feature_image)}
                className="post-feature-image pt-0"
                alt=""
              />
            ) : // <figure className="post-feature-image pt-5" style={{ backgroundImage: `url(${post.feature_image})` }}>
              // </figure>
              null}
            <div className="row">
              <div className="col-md-8 order-lg-first order-last">
                <section
                  className="post-full-content"
                  style={{ background: "transparent" }}
                >
                  <h1
                    className={`${darkMode
                      ? "content-title content-title-dark"
                      : "content-title"
                      }`}
                  >
                    {post.title}
                  </h1>

                  {/* The main post content */}
                  <LazyLoadComponent>
                    <section
                      className={`mt-3 ${darkMode
                        ? "content-body load-external-scripts content_body_dark"
                        : "content-body load-external-scripts"
                        }`}
                      dangerouslySetInnerHTML={{
                        __html: post.html,
                      }}
                    />
                  </LazyLoadComponent>
                </section>
              </div>
              <div className="col-lg-4" style={{ position: "relative" }}>
                <div
                  className="singlePost-article"
                  style={{
                    position: "sticky",
                    top: "80px",
                    paddingRight: "0px",
                  }}
                >
                  {/* <div className="singlePost-article-title">
                    <p>СОДЕРЖАНИЕ</p>
                  </div> */}
                  <div
                    className={`mt-3 ${darkMode ? "content_dark" : ""
                      } aboutBlog-article-links `}
                  >
                    <ul>
                      {allH2
                        ? allH2.map((h2) => {
                          return (
                            <li
                              key={h2.title}
                              style={{
                                listStyleType: "none",
                                cursor: "pointer",
                              }}
                            >
                              <a onClick={() => onClickLink(h2.id)}>
                                {h2.title}
                              </a>
                            </li>
                          );
                        })
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        <LazyLoadComponent>
          <BlogSlider />
        </LazyLoadComponent>
        <div className="mb-5 text-center">
          <div className="load-more-img">
            {darkMode ? (
              <StaticImage
                src="../../../static/images/white_hand.png"
                alt="hand"
              />
            ) : (
              <StaticImage
                src="../../../static/images/heartHand.png"
                alt="hand"
              />
            )}
            {/* <img src={darkMode ? WhiteHand : HeartHand} width="70px" height="70px" alt="" /> */}
          </div>

          <a href="/#all_blogs" className="button_Load">
            все статьи о мгимо
          </a>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;
